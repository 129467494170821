.main-content {
  padding: 2rem;
  flex: auto;
  min-height: 0;

  &.main-content__public {
    position: relative;
    display: flex;
    padding: 0;
    flex-direction: column;
  }
}