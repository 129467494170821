@import '../../styles/variables';
.steps {

  .ant-steps {
    width: 100%;
  }

  .steps__action {
    width: 100%;
    max-width: 60rem;
    margin: 3rem 0 0 auto;
  }
}
.preview-header {
  width: 100%;
  margin-left:auto;
  text-align: center;
  padding: 2rem 0;
  background: map-get($colors, white);
  margin-bottom: 5rem;
  border: .1rem solid map-get($colors, gray);
}
.steps-form-actions{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.ant-form-item-control {
  line-height: 20px !important;
}
.register-footer-help {
  width: 100%;
  margin-left:auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 10rem;
  & > div {
    text-align: center;
    padding: 1rem;
    margin-top: 1rem;
    background: map-get($colors, white);
    border: .1rem solid map-get($colors, gray);
  }
}
.register {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
}
