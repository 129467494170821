@import '../../styles/variables';
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: map_get($colors, bgColoc);

  .footer__link {
    color: map-get($colors, darkSecond);
    margin-right: 3rem;
    text-decoration: underline;

    &:hover {
      color: map-get($colors, darkThird);
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
  &.public-footer {
    //position: absolute;
    //left: 0;
    //bottom: 0;
    //width: 100%;
  }
}