$colors: (
  white: #FFFFFF,
  black: #333333,
  blue: #008EFE,
  blueSecond: #C9DAFB,
  orange: #E69900,
  gray: #E0E0E0,
  dark: #333333,
  darkSecond: #828282,
  darkThird: #636b6f,
  bgColoc: #f5f7fb
)