.dropzone {
  height: 20rem;
  background-color: rgba(#008EFE, .08);
  border: .2rem dashed rgba(#008EFE, .3);
  border-radius: .4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;

  &.dropzone--dragged {
    background-color: rgba(#008EFE, .2);
    border-color: #008EFE;

    &:after {
      content: attr(data-text);
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #008EFE;
      font-size: 1.8rem;
      font-weight: 500;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
    }
  }

  &.dropzone--loading {
    border: none;
  }

  .dropzone__file {
    &-item {
      display: flex;
      flex-direction: column;
    }
  }

  .dropzone__text {
    font-size: 1.6rem;
    color: #333333;

    &.dropzone__text--light {
      color: #949494;
    }
  }

  .file {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    color: #949494;
  }
}